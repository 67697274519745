import { Interpolation } from "@emotion/react";

import { ITheme } from "@theme";
import { Classes } from "./types";

export const createCss = (): Record<Classes, Interpolation<ITheme>> => ({
  root: (theme) => ({
    padding: "160px 20px 60px",

    "& ul": {
      paddingLeft: "1.3em",
    },

    [theme.mediaRequests.notMobile]: {
      display: "flex",
      margin: "56px auto 0",
      maxWidth: 1240,
      paddingTop: 30,
    },
  }),

  title: {
    marginBottom: 40,
  },

  menuWrapper: {
    width: 260,
  },

  mobileMenuWrapper: {
    backgroundColor: "white",
    boxShadow: "0 2px 4px 0 rgba(0, 0, 0, .08), 0 0 4px 0 rgba(0, 0, 0, .12)",
    left: 0,
    padding: "30px 16px 15px 16px",
    position: "fixed",
    top: 50,
    width: "100%",

    "& .trigger-btn": {
      justifyContent: "space-between",
    },
  },

  content: (theme) => ({
    [theme.mediaRequests.notMobile]: {
      flex: 1,
    },
  }),

  menu: {
    listStyle: "none",
    margin: 0,
    padding: 0,
    paddingTop: 200,
    position: "fixed",
    top: 0,

    "& li": {
      fontSize: 18,
      lineHeight: "35px",
    },
  },
});
