import React, { Fragment } from "react";
import clsx from "clsx";
import { Link } from "react-router-dom";

import { createCss } from "./styles";
import { ReactGAEventWait } from "@helpers/ga";
import { FooterProps } from "./types";
import { ROUTES } from "@constants";

import whiteLogoImage from "@images/white-logo.svg";
import SponsorsImage from "@images/sponsors.jpg";

const Footer: React.FC<FooterProps> = ({
  color = "primary",
  withSponsors = false,
}) => {
  const css = createCss();

  return (
    <Fragment>
      {withSponsors && (
        <div
          style={{
            backgroundImage: `url(${SponsorsImage})`,
            backgroundPosition: "center",
            height: 340,
          }}
        ></div>
      )}
      <footer css={css.root} id="footer">
        <div css={css.container}>
          <div>
            <Link to="/">
              <img src={whiteLogoImage} alt="logo" css={css.logo} />
            </Link>
          </div>
          <div css={css.links}>
            <div
              css={css.linksHeading}
              className={clsx({
                [color]: true,
              })}
            >
              Site links
            </div>
            <div>
              <Link
                to={ROUTES.INVESTORS}
                onClick={() => {
                  ReactGAEventWait({
                    action: "InvestorsRelationsClick",
                    category: "Footer",
                  });
                }}
              >
                Investors
              </Link>
            </div>
            <div>
              <Link to={ROUTES.TERMS}>Terms of use</Link>
            </div>
          </div>
          <div css={css.links}>
            <div
              css={css.linksHeading}
              className={clsx({
                [color]: true,
              })}
            >
              Contact
            </div>
            <div>
              <a
                data-test="support-email"
                href="mailto:support@parentdayout.com"
                onClick={() => {
                  ReactGAEventWait({
                    action: "EmailClick",
                    category: "Footer",
                  });
                }}
              >
                support@parentdayout.com
              </a>
            </div>
          </div>
        </div>
      </footer>
    </Fragment>
  );
};

export default Footer;
