import { Interpolation } from "@emotion/react";

import { ITheme } from "@theme";
import { Classes } from "./types";

const teal = "#01eeda";

export const createCss = (): Record<Classes, Interpolation<ITheme>> => ({
  root: (theme) => ({
    background: theme.palette.text.primary,
    color: "white",
    fontSize: 21,
    fontWeight: 600,
    padding: "100px 0",

    [theme.mediaRequests.mobile]: {
      fontSize: 16,
      fontWeight: 400,
      padding: "55px 0",
    },

    "& p": {
      margin: 0,
    },
  }),

  links: {
    "& a": {
      color: "white",

      "&:hover": {
        textDecoration: "none",
      },
    },
  },

  linksHeading: (theme) => ({
    color: teal,
    marginBottom: 20,

    "&.primary": {
      color: theme.palette.yellow.main,
    },

    [theme.mediaRequests.mobile]: {
      marginTop: 30,
    },
  }),

  container: (theme) => ({
    display: "flex",
    justifyContent: "space-between",
    margin: "0 auto",
    maxWidth: 1260,
    padding: "0 30px",

    [theme.mediaRequests.mobile]: {
      flexDirection: "column",
    },
  }),

  logo: (theme) => ({
    display: "block",
    marginBottom: 40,
    width: 250,

    [theme.mediaRequests.mobile]: {
      margin: "0 auto 40px",
      width: 160,
    },
  }),
});
