import React, { useState, useEffect, Fragment } from "react";
import { css as emotionCss } from "@emotion/css";
import Helmet from "react-helmet";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";

import Typography from "@ui-kit/Typography";
import { createCss } from "./styles";
import Header from "@components/Header";
import Footer from "@components/FooterNew";
import META from "./meta";
import Button from "@ui-kit/Button";
import { useViewport } from "@hooks/useViewport";

const MENU_ITEMS = [
  {
    id: "mouth-recommendations",
    name: "Word of mouth",
  },
  {
    id: "atmosphere",
    name: "Atmosphere",
  },
  {
    id: "classrooms",
    name: "Classrooms",
  },
  {
    id: "communication-with-parents",
    name: "Communication",
  },
  {
    id: "drop-in-and-pick-up",
    name: "Drop-off & Pick-up",
  },
  {
    id: "daily-schedule",
    name: "Daily schedule",
  },
  {
    id: "holiday-schedule",
    name: "Holiday schedule",
  },
  {
    id: "meals",
    name: "Meals",
  },
  {
    id: "management-and-staff",
    name: "Management / staff",
  },
  {
    id: "safety-and-security",
    name: "Safety & Security",
  },
  {
    id: "licensing",
    name: "Licensing",
  },
  {
    id: "enrollment",
    name: "Enrollment",
  },
  {
    id: "dont-forget-to-grab",
    name: "Don’t forget...",
  },
];

const QuestionnairePage: React.FC = () => {
  const [menuAnchorElement, setMenuAnchorElement] =
    useState<HTMLElement | null>(null);
  const css = createCss();
  const { isMobile } = useViewport();

  useEffect(() => {
    document.addEventListener("scroll", checkOffset);
    checkOffset();

    return () => {
      document.removeEventListener("scroll", checkOffset);
    };
  }, []);

  const getRectTop = (el: HTMLElement) => {
    return el.getBoundingClientRect().top;
  };

  const checkOffset = () => {
    const menu = document.querySelector("#menu") as HTMLElement;
    const footer = document.querySelector("#footer") as HTMLElement;

    if (!menu || !footer) {
      return;
    }

    const footerPosition = getRectTop(footer) + document.body.scrollTop;
    const visibleFooter =
      document.body.scrollTop + window.innerHeight - footerPosition;
    const leftPage = window.innerHeight - visibleFooter;
    const diff = menu.clientHeight - leftPage + 20;

    if (diff > 0) {
      menu.style.top = `${-1 * diff}px`;
    } else {
      menu.style.top = "0px";
    }
  };

  const scrollToItem = (id: string) => {
    const yOffset = isMobile ? -138 : -60;
    const element = document.getElementById(id);

    if (!element) {
      return;
    }

    const y =
      element.getBoundingClientRect().top + window.pageYOffset + yOffset;

    window.scrollTo({ top: y });
  };

  return (
    <Fragment>
      <div css={css.root} data-test="questionnaire-page">
        <Helmet {...META} />
        <Header fixed />

        {isMobile ? (
          <div css={css.mobileMenuWrapper}>
            <Button
              aria-owns={menuAnchorElement ? "menu" : undefined}
              aria-haspopup="true"
              variant="outlined"
              data-test="mobile-menu-trigger"
              onClick={(event) => setMenuAnchorElement(event.currentTarget)}
              fullWidth
              className="trigger-btn"
            >
              Table of contents
              <ArrowDropDownIcon />
            </Button>
            <Menu
              id="menu"
              anchorEl={menuAnchorElement}
              open={Boolean(menuAnchorElement)}
              onClose={() => setMenuAnchorElement(null)}
              classes={{
                paper: emotionCss({
                  width: "100%",
                }),
              }}
            >
              {MENU_ITEMS.map((i) => (
                <MenuItem
                  onClick={() => {
                    setMenuAnchorElement(null);
                    scrollToItem(i.id);
                  }}
                  key={i.id}
                >
                  {i.name}
                </MenuItem>
              ))}
            </Menu>
          </div>
        ) : (
          <div css={css.menuWrapper}>
            <ul css={css.menu} id="menu">
              {MENU_ITEMS.map((i) => (
                <li
                  key={i.id}
                  className="g-link"
                  onClick={() => scrollToItem(i.id)}
                >
                  {i.name}
                </li>
              ))}
            </ul>
          </div>
        )}

        <div css={css.content}>
          <Typography variant="h2" align="center">
            Touring a daycare?
          </Typography>
          <Typography variant="h3" paragraph align="center" gap>
            Top questions to ask
          </Typography>

          <Typography variant="h4" paragraph bolded id="mouth-recommendations">
            Word of mouth recommendations
          </Typography>
          <Typography paragraph align={isMobile ? "justify" : "left"}>
            To get the most insight from word of mouth recommendations, ask the
            person to rate the school along the following parameters, on a 1-10
            scale:
          </Typography>
          <Typography
            paragraph
            component="div"
            align={isMobile ? "justify" : "left"}
          >
            <ul>
              <li>Management</li>
              <li>Staff</li>
              <li>Responsiveness</li>
              <li>Care quality</li>
              <li>Curriculum quality</li>
              <li>Cleanliness</li>
              <li>Drop-off & pick-up</li>
              <li>Meals</li>
            </ul>
          </Typography>

          <Typography variant="h4" paragraph bolded id="atmosphere">
            Atmosphere
          </Typography>
          <Typography
            paragraph
            component="div"
            align={isMobile ? "justify" : "left"}
          >
            <ul>
              <li>
                Does the facility look clean? Does it smell clean? How loud is
                it inside?
              </li>
              <li>Are the restrooms clean and sanitary?</li>
              <li>Are the classrooms free of clutter and neatly organized?</li>
            </ul>
          </Typography>

          <Typography variant="h4" paragraph bolded id="classrooms">
            Classrooms
          </Typography>
          <Typography
            paragraph
            component="div"
            align={isMobile ? "justify" : "left"}
          >
            <ul>
              <li>How many kids will be in my child’s classroom?</li>
              <li>What is the teacher:child ratio for my child’s age group?</li>
              <li>
                What toys and learning materials will my child have access to?
              </li>
            </ul>
          </Typography>

          <Typography
            variant="h4"
            paragraph
            bolded
            id="communication-with-parents"
          >
            Communication w. parents
          </Typography>
          <Typography
            paragraph
            component="div"
            align={isMobile ? "justify" : "left"}
          >
            <ul>
              <li>Do parents receive photo updates during the day?</li>
              <li>
                Are day sheets provided in a written or electronic format? If
                electronic, which app is used?
              </li>
            </ul>
          </Typography>

          <Typography variant="h4" paragraph bolded id="drop-in-and-pick-up">
            Drop-off & Pick-up
          </Typography>
          <Typography
            paragraph
            component="div"
            align={isMobile ? "justify" : "left"}
          >
            <ul>
              <li>What time is drop-off and pick-up?</li>
              <li>What are the drop-off and pick-up procedures?</li>
              <li>
                What time is early drop-off and late pick-up? What is the fee
                for this service?
              </li>
            </ul>
          </Typography>

          <Typography variant="h4" paragraph bolded id="daily-schedule">
            Daily schedule
          </Typography>
          <Typography
            paragraph
            component="div"
            align={isMobile ? "justify" : "left"}
          >
            <ul>
              <li>
                What will be my child’s daily schedule (play time, learning,
                eating, resting)?
              </li>
              <li>
                When during the day will my child be taken outside? For how
                long?
              </li>
            </ul>
          </Typography>

          <Typography variant="h4" paragraph bolded id="holiday-schedule">
            Holiday schedule
          </Typography>
          <Typography
            paragraph
            component="div"
            align={isMobile ? "justify" : "left"}
          >
            <ul>
              <li>
                On which days during the school year will the facility be
                closed?
              </li>
              <li>
                How many parent / teacher conferences are scheduled per school
                year?
              </li>
            </ul>
          </Typography>

          <Typography variant="h4" paragraph bolded id="meals">
            Meals
          </Typography>
          <Typography
            paragraph
            component="div"
            align={isMobile ? "justify" : "left"}
          >
            <ul>
              <li>
                Does the school offer a meal plan? Which meals are included? How
                much is the plan?
              </li>
              <li>
                How does the school manage children with food allergies or
                dietary restrictions?
              </li>
            </ul>
          </Typography>

          <Typography variant="h4" paragraph bolded id="management-and-staff">
            Management / staff
          </Typography>
          <Typography
            paragraph
            component="div"
            align={isMobile ? "justify" : "left"}
          >
            <ul>
              <li>How long has the facility director been with the school?</li>
              <li>
                Over the past 5 years, how many facility directors has the
                school employed?
              </li>
              <li>
                Over the previous 2 years, what percentage of the staff has
                turned over?
              </li>
            </ul>
          </Typography>

          <Typography variant="h4" paragraph bolded id="safety-and-security">
            Safety & Security
          </Typography>
          <Typography
            paragraph
            component="div"
            align={isMobile ? "justify" : "left"}
          >
            <ul>
              <li>
                What percentage of caregivers have first aid certifications?
              </li>
              <li>
                Which of the following background checks are administered prior
                to employment: criminal history, fingerprints, child abuse and
                neglect registry, sex offender registry
              </li>
            </ul>
          </Typography>

          <Typography variant="h4" paragraph bolded id="licensing">
            Licensing
          </Typography>
          <Typography
            paragraph
            component="div"
            align={isMobile ? "justify" : "left"}
          >
            <ul>
              <li>What is the school’s current enrollment?</li>
              <li>What is the school’s licensed enrollment capacity?</li>
              <li>
                What age groups is the facility licensed to serve (min age / max
                age)?
              </li>
            </ul>
          </Typography>

          <Typography variant="h4" paragraph bolded id="enrollment">
            Enrollment
          </Typography>
          <Typography
            paragraph
            component="div"
            align={isMobile ? "justify" : "left"}
          >
            <ul>
              <li>Are part-time enrollment options available?</li>
              <li>
                Is there currently a waitlist? If yes, when is the next opening
                for my child’s age-group?
              </li>
              <li>
                How much advance notice does the school require to terminate
                enrollment?
              </li>
            </ul>
          </Typography>

          <Typography variant="h4" paragraph bolded id="dont-forget-to-grab">
            Don’t forget to grab ...
          </Typography>
          <Typography
            paragraph
            component="div"
            align={isMobile ? "justify" : "left"}
          >
            <ul>
              <li>
                Enrollment Application, along with any other registration forms
              </li>
              <li>
                Tuition & Fees Schedule, showing all service-related expenses
              </li>
              <li>Parent Handbook, detailing center’s policies & procedures</li>
              <li>
                Child Day Sheet, showing which activities are tracked throughout
                the day
              </li>
              <li>Menu, with description of meals served</li>
            </ul>
          </Typography>
        </div>
      </div>
      <Footer />
    </Fragment>
  );
};

export default QuestionnairePage;
